import { useRecruitmentApi } from 'api';
import useCrudService from 'shared/crud';
import { downloadFile } from 'shared/fileHelper';

export const RECRUITING_REPORT_GENERATION_READ = 'RECRUITING_REPORT_GENERATION_READ';
export const KERNEL_READ_RECRUITING_REPORT = 'COMPANY_READ_RECRUITING_REPORTS';

export const REPORT_TYPES: { [key: string]: string } = {
  DATA_DEBUG: 'Raw Data Report',
  SLA: 'SLA Report',
  RECRUITMENT_FUNNEL: 'Recruitment Funnel Report',
  FORECAST_ACCURACY: 'Forecast Accuracy Report',
  LEAD_TIME: 'Lead Time Report',
  CAPACITY: 'Capacity Report',
  DROP_OUT: 'Drop Out Report',
  BID_ASK: 'Bid-Ask Report',
  REJECTED_CANDIDATES: 'Rejected Candidates Report',
  SOURCES: 'Sources Report',
};

export const REPORT_STATUSES_ALIAS = {
  IN_PROGRESS: 'IN_PROGRESS',
  GENERATED: 'GENERATED',
};

export const REPORT_STATUSES = {
  IN_PROGRESS: 'In Progress',
  GENERATED: 'Generated',
};

const REPORT_API_PATH = '/reports';

const useRecruitingReportService = () => {
  const { create: baseCreate, ...baseCrud } = useCrudService({
    singleResourceUrl: `${REPORT_API_PATH}/:id`,
    listResourceUrl: REPORT_API_PATH,
    apiService: useRecruitmentApi,
  });

  const { sendGetRequest } = useRecruitmentApi();

  const create = async (request: any) => {
    return baseCreate({
      ...request,
      filter: {},
    });
  };

  const downloadReport = async (id: string) => {
    const response = await sendGetRequest(`/reports/${id}`);
    downloadFile(response);
  };

  return {
    create,
    downloadReport,
    ...baseCrud,
  };
};

export default useRecruitingReportService;
